import ScheduleItem from './schedule-item';

export default class ScheduleList {
  isError = false;

  list = [];

  constructor(req, res) {
    if (!res || !Object.keys(res).length || !res.schedule) {
      this.isError = true;
    } else {
      this.list = res.schedule.map(item => new ScheduleItem({schedule:item, dictionary: res.dictionaries, req}));
    }
  }

  get isFull() {
    return this.list && this.list.length;
  }

  closeAll() {
    this.list.forEach(item => {
      item.opened = false;
    });
  }
};