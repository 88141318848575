import TabloStatus from './tablo-status';
import TabloItemPoint from './tablo-item-point';

export default class TabloItem {
  #item = null;

  #dictionary = null;

  #status = null;

  #from = null;

  #to = null;

  constructor(data) {
    this.#item = data.tablo;
    this.#dictionary = data.dictionary;
    this.#status = new TabloStatus(data.tablo.status);

    this.#from = new TabloItemPoint(data.tablo.from, data.dictionary);
    this.#to = new TabloItemPoint(data.tablo.to, data.dictionary);
  }

  get company() {
    return this.#item.company;
  }

  get gate() {
    return this.#item.gate ? this.#item.gate.join(',') : '';
  }

  get plane() {
    return this.#item.airplane;
  }

  get planeName() {
    return this.plane ? this.#dictionary.airplanes[this.plane].name : '';
  }

  get checkInDescs() {
    return this.#item.checkInDesc ? this.#item.checkInDesc.join(',') : '';
  }

  get companyName() {
    return this.#dictionary.companies[this.company].name;
  }

  get number() {
    return this.#item.number;
  }

  get uniqueString() {
    return this.#item.company + this.#item.number;
  }

  get from() {
    return this.#from;
  }

  get to() {
    return this.#to;
  }

  get status() {
    return this.#status;
  }

  get description() {
    return this.#item.description;
  }

  get plannedDate() {
    return this.#item.planedDate;
  }
}