import Vue from 'vue';
import {mapGetters} from 'vuex';
import filters from 'razlet-sdk/lib/filters/base';

function stringDateToDate(date) {
  let dateObj;
  if (typeof date === 'string') {
    if (/^\d{4}-(0\d{1}|1[012])-([012]\d{1}|3[012])\s([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/.test(date)) {
      dateObj = new Date(date.replace(/-/g, '/'));
    } else {
      dateObj = new Date(date);
    }
  } else {
    dateObj = date;
  }
  return dateObj;
}

Vue.mixin({
  computed: {
    ...mapGetters(['isMobile', 'isOffline']),
    isDev() {
      return !/\/\/api.razlet.ru/.test(process.env.asb3URL);
    },
    isSulu() {
      return process.env.type === 'sulu';
    },
    isKg() {
      return true;
    },
    isUser() {
      return false;
    },
    suluUrl() {
      return process.env.suluURL;
    },
  },
});

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

Vue.filter('dateWithMonth', function(date) {
  const dateObj = stringDateToDate(date);
  return Vue.prototype.$localize.d(dateObj, 'dateWithMonth');
});

const COUNTRIES = {
  RUB: 'ru',
  USD: 'en',
  'KGS': 'ru-KG',
  'KGS-kg': 'ru-KG',
  'KGS-ru': 'ru-KG',
  'KGS-en': 'ru-US',
};

const getKGCurrency = (currency) => `${currency}-${Vue.prototype.$locale}`;
const getCurrency = (currency) => currency === 'KGS' ? getKGCurrency(currency) : currency;

Vue.prototype.$localizeCurrency = (value, currency) => `${Vue.prototype.$localize.n(value, 'currency', COUNTRIES[getCurrency(currency)])}`;
Vue.prototype.$company = 'YC';