import {onDocumentLoad} from '~/utils';

const loadAffiliator = () => {
  if (customElements && customElements.get('razlet-affiliator')) return false;
  const script = document.createElement('script');
  script.src = `${process.env.affURL}/yamal/avia/main.js`;
  script.type = 'text/javascript';
  script.defer = true;
  document.getElementsByTagName('body')[0].appendChild(script);
  window.removeEventListener('load', () => {});
  return true;
};

export default() => {
  onDocumentLoad(loadAffiliator);
};