export function splitTime(t) {
  const [h, m] = t.split(':');

  return {hours: h.charAt(0) === '0' ? Number(h.charAt(1)) : Number(h), minutes: m.charAt(0) === '0' ? Number(m.charAt(1)) : Number(m)};
}

export function splitTimeRange(r) {
  return r.split('-').map(t => splitTime(t));
}

export function isTimeInRange(time, range) {
  if (!time) return false;
  const timeObj = splitTime(time);
  const rangeArr = splitTimeRange(range);
  return (rangeArr[0].hours <= timeObj.hours && (timeObj.hours < rangeArr[1].hours || rangeArr[1].hours === 0)) || (timeObj.hours === rangeArr[1].hours && timeObj.minutes === 0);
}

export function debounce(func, wait) {
  let call = null;
  return function(...args) {
    if (call) clearTimeout(call);
    call = setTimeout(() => {
      func(...args);
    }, wait);
  };
}

export const onDocumentLoad = (callback, delay = 2000) => {
  if (document.readyState === 'complete') {
    setTimeout(() => callback(), delay);
  } else {
    window.addEventListener('load', function() {
      setTimeout(() => callback(), delay);
    });
  }
};

export function capitalize(string) {
  if (string && string.length) return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  return '';
};