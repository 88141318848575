import Vue from 'vue';

const doubleRequestAnimationFrame = (callback) => {
  requestAnimationFrame(() => {
    requestAnimationFrame(callback);
  });
};
  
// eslint-disable-next-line consistent-return
const forceNextTick = (callback) => {
  if (callback && typeof callback === 'function') {
    doubleRequestAnimationFrame(callback);
  } else {
    return new Promise(resolve => {
      doubleRequestAnimationFrame(resolve);
    });
  }
};
  

Vue.$forceNextTick = forceNextTick;
Vue.prototype.$forceNextTick = forceNextTick;