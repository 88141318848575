import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0060161d = () => interopDefault(import('../src/pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _af8fb2e4 = () => interopDefault(import('../src/pages/sale.vue' /* webpackChunkName: "pages/sale" */))
const _430278ba = () => interopDefault(import('../src/pages/about/map.vue' /* webpackChunkName: "pages/about/map" */))
const _4acfd64a = () => interopDefault(import('../src/pages/passazhiram/refund.vue' /* webpackChunkName: "pages/passazhiram/refund" */))
const _85db2686 = () => interopDefault(import('../src/pages/passazhiram/schedule/index.vue' /* webpackChunkName: "pages/passazhiram/schedule/index" */))
const _5715fbbe = () => interopDefault(import('../src/pages/passazhiram/search-ticket.vue' /* webpackChunkName: "pages/passazhiram/search-ticket" */))
const _374fd91b = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2c77f01d = () => interopDefault(import('../src/pages/passazhiram/schedule/_departure/index.vue' /* webpackChunkName: "pages/passazhiram/schedule/_departure/index" */))
const _59be85fc = () => interopDefault(import('../src/pages/passazhiram/schedule/_departure/_arrival/index.vue' /* webpackChunkName: "pages/passazhiram/schedule/_departure/_arrival/index" */))
const _620030aa = () => interopDefault(import('../src/pages/news/_.vue' /* webpackChunkName: "pages/news/_" */))
const _5e55bab0 = () => interopDefault(import('../src/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/news",
    component: _0060161d,
    meta: {"name":"news-list-page"},
    name: "news"
  }, {
    path: "/sale",
    component: _af8fb2e4,
    meta: {"name":"sale","layout":"affiliator"},
    name: "sale"
  }, {
    path: "/about/map",
    component: _430278ba,
    meta: {"name":"about-map"},
    name: "about-map"
  }, {
    path: "/passazhiram/refund",
    component: _4acfd64a,
    meta: {"name":"refund-page"},
    name: "passazhiram-refund"
  }, {
    path: "/passazhiram/schedule",
    component: _85db2686,
    meta: {"name":"schedule-page-departure","layout":"blue"},
    name: "passazhiram-schedule"
  }, {
    path: "/passazhiram/search-ticket",
    component: _5715fbbe,
    meta: {"name":"refund-page"},
    name: "passazhiram-search-ticket"
  }, {
    path: "/",
    component: _374fd91b,
    meta: {"name":"index-page","layout":"index"},
    name: "index"
  }, {
    path: "/passazhiram/schedule/:departure",
    component: _2c77f01d,
    meta: {"name":"schedule-page-arrival","layout":"blue"},
    name: "passazhiram-schedule-departure"
  }, {
    path: "/passazhiram/schedule/:departure/:arrival",
    component: _59be85fc,
    meta: {"name":"schedule-page","layout":"blue"},
    name: "passazhiram-schedule-departure-arrival"
  }, {
    path: "/news/*",
    component: _620030aa,
    meta: {"name":"news-page"},
    name: "news-all"
  }, {
    path: "/*",
    component: _5e55bab0,
    meta: {"name":"headless-page"},
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
