import Vue from 'vue';
import Asb3Sender from 'razlet-sdk/lib/api/asb3-sender';
import {sendError} from '~/utils/error';
import TabloList from '~/entities/tablo-list';
import ScheduleList from '~/entities/schedule-list';
// import sulu from './sulu';

const state = () => ({
  ua: /iPhone|iPad|iPod|Android|Windows Phone/.test(typeof window !== 'undefined' ? window.navigator.userAgent : ''),
  mobile: false,
  offline: false,
  host: null,
});

const mutations = {
  setMobile(state, value) {
    state.mobile = value;
  },
  setOffline: (state, value) => {
    state.offline = value;
  },
};

const getters = {
  isMobile(state) {
    return state.mobile;
  },
  isOffline(state) {
    return state.offline;
  },
};

const actions = {
  async nuxtServerInit ({ dispatch }, {app, req, isDev}) {
    const currentLang = app.$cookiz.get('defaultLanguage') || 'ru';
    Vue.prototype.$locale = currentLang;
    await app.i18n.setLocale(currentLang);
    await dispatch('sulu/initSuluSender', {req, isDev, requests: ['getMenu']});
  },
  async getTablo({state}, req) {
    const asb3Sender = new Asb3Sender();
    const response = await asb3Sender.get(`/api/web/schedule/yamal/flight-table?city=${state.sulu.about.code}&${req.buildRequest()}`, null)
      .then(res => {
        return Promise.resolve(new TabloList(res.data, req.timeOptions, req.direction.altValue));
      })
      .catch(e => {
        sendError(e);
      });
    return response;
  },
  // eslint-disable-next-line no-empty-pattern
  async getSchedule({}, req) {
    const asb3Sender = new Asb3Sender();
    const response = await asb3Sender.get(`/api/web/schedule${req.buildRequest()}company=YC`, null)
      .then(res => {
        return Promise.resolve(new ScheduleList(req, res.data));
      })
      .catch(e => {
        sendError(e);
      });
    return response;
  },
};

export default {
  strict: false,
  state,
  getters,
  mutations,
  actions,
  // modules: {
  //   sulu: {
  //     namespaced: true,
  //     ...sulu,
  //   },
  // },
};