import Vue from 'vue';

export default class TabloStatus {
  #item = null;

  greenStatuses = ['checkinboarding', 'checkin', 'checkinmanas2'];

  greyStatuses = ['by_schedule'];

  redStatuses = ['canceled', 'cancelled', 'delayed'];

  blueStatuses = ['arrived', 'departed', 'asap', 'estimated', 'by_flight'];

  orangeStatuses = ['manas2', 'boarding', 'checkinclosed'];

  constructor(data) {
    this.#item = data;
  }

  get code() {
    const code = this.#item.replace(/\s+/g, '').replace(/-/g, '').replace(/_/g, '').toLowerCase();
    const status = /^by/.test(code) ? 'by_flight' : code;
    return status;
  }

  get name() {
    if (!this.code || this.code === '-') return '';
    const key = `tablo.statuses.${this.code}`;
    const name = Vue.prototype.$localize.t(key);

    if (key === name) return '';
    return this.code === 'by_flight' ? `${name} ${this.#item.replace('by', '')}` : name;
  }

  get color() {
    if (this.greenStatuses.includes(this.code)) return '#84B550';
    if (this.greyStatuses.includes(this.code)) return '#A9B2C6';
    if (this.redStatuses.includes(this.code)) return '#D50000';
    if (this.orangeStatuses.includes(this.code)) return '#ED8923';
    return '#1AA1ED';
  }

  get icon() {
    switch (this.code) {
    case 'checkin':
    case 'checkinboarding':
    case 'checkinmanas2':
      return 'checkin';
    case 'by_schedule':
      return 'schedule';
    case 'canceled':
    case 'cancelled':
      return 'canceled';
    case 'delayed':
      return 'delayed';
    case 'arrived':
      return 'arrived';
    case 'departed':
      return 'departed';
    case 'asap':
    case 'estimated':
    case 'by_flight':
      return 'process';
    case 'boarding':
    case 'checkinclosed':
      return 'boarding';
    case 'manas2':
      return 'manas2';
    default:
      return 'process';
    }
  }
}