/* eslint-disable  */
export default async (context) => {
  const workbox = await window.$workbox;

  window.addEventListener('offline', () => {
    context.store.commit('setOffline', true);
  });
  window.addEventListener('online', () => {
    context.store.commit('setOffline', false);
  });

  if (!workbox) {
    console.debug('Workbox couldn\'t be loaded.');
    console.log('Workbox couldn\'t be loaded.');
    return;
  }

  console.log('Workbox was loading.');

  workbox.addEventListener('installed', (event) => {
    if (!event.isUpdate) {
      console.debug('The PWA is on the latest version.');
      return;
    }

    console.log('There is an update for the PWA, reloading...');
    window.location.reload();
  });
};