import Vue from 'vue';
import {date02format} from 'razlet-sdk/lib/utils/date';

export const weekdays = {
  ru: [
    { num: 1,  full: 'понедельник', short: 'пн' },
    { num: 2,  full: 'вторник',     short: 'вт' },
    { num: 3,  full: 'среда',       short: 'ср' },
    { num: 4,  full: 'четверг',     short: 'чт' },
    { num: 5,  full: 'пятница',     short: 'пт' },
    { num: 6,  full: 'суббота',     short: 'сб' },
    { num: 7,  full: 'воскресенье', short: 'вс' },
  ],
  en: [
    { num: 1,  full: 'monday',    short: 'mon' },
    { num: 2,  full: 'tuesday',   short: 'tue' },
    { num: 3,  full: 'wednesday', short: 'wed' },
    { num: 4,  full: 'thursday',  short: 'thu' },
    { num: 5,  full: 'friday',    short: 'fri' },
    { num: 6,  full: 'saturday',  short: 'sat' },
    { num: 7,  full: 'sunday',    short: 'sun' },
  ],
};

export default class ScheduleItem {
  #item = null;

  #dictionary = null;

  #req = null;

  constructor(data) {
    this.#item = data.schedule;
    this.#dictionary = data.dictionary;
    this.#req = data.req;
  }

  get request() {
    return this.#req;
  }

  get flight() {
    return this.#item.flights[0];
  }

  get company() {
    return this.flight.company;
  }

  get companyName() {
    return this.#dictionary.companies[this.company].name;
  }

  get number() {
    return this.flight.number;
  }

  get fromCity() {
    return this.#dictionary.cities[this.flight.fromCity].name;
  }

  get toCity() {
    return this.#dictionary.cities[this.flight.toCity].name;
  }

  get fromCityCode() {
    return this.flight.fromCity;
  }

  get toCityCode() {
    return this.flight.toCity;
  }

  get fromAirport() {
    return this.#dictionary.airports[this.flight.fromAirport].name;
  }

  get toAirport() {
    return this.#dictionary.airports[this.flight.toAirport].name;
  }

  get fromAirportCode() {
    return this.flight.fromAirport;
  }

  get toAirportCode() {
    return this.flight.toAirport;
  }

  get from() {
    return `${this.fromCity} (${this.fromAirport})`;
  }

  get to() {
    return `${this.toCity} (${this.toAirport})`;
  }

  // eslint-disable-next-line class-methods-use-this
  get days() {
    return weekdays[Vue.prototype.$locale].map(weekday => {
      return {
        name: weekday.short,
        id: weekday.num,
        active: this.#item.frequency.includes(String(weekday.num)),
        date: this.#req.weekDates[Number(weekday.num) - 1],
      };
    });
  }

  get fromTime() {
    return this.flight.fromTime;
  }

  get toTime() {
    return this.flight.toTime;
  }

  get begin() {
    return this.convertDate(this.#item.periodBegin);
  }

  get end() {
    return this.convertDate(this.#item.periodEnd);
  }

  get navigation() {
    return `${this.begin} - ${this.end}`;
  }

  get time() {
    return `${this.fromTime} - ${this.toTime}`;
  }

  // eslint-disable-next-line class-methods-use-this
  convertDate(date) {
    const d = new Date(date);
    const day = d.getDate();
    const month = d.getMonth() + 1;
    return `${date02format(day, 2)}.${date02format(month, 2)}`;
  }
}
